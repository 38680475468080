import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import LoadingSpinner from '../LoadingSpinner';

const PageLoadingSpinner = props => <LoadingSpinner {...props} />;

PageLoadingSpinner.propTypes = {
  caller: PropTypes.string.isRequired,
  outerClassName: PropTypes.string,
  iconOuterClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  delay: PropTypes.number,
  hideDelay: PropTypes.number
};
PageLoadingSpinner.defaultProps = {
  outerClassName: 'mx-auto my-5 p-5 text-center',
  iconOuterClassName: 'mx-auto my-5 p-5 h3',
  iconClassName: 'fas pumahara-logo fa-spin',
  delay: 0,
  hideDelay: 0
};

export default PageLoadingSpinner;
