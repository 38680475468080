import {
  lazy
} from 'react';
// import {
//   hasRoles
// } from '../App/functions';
import {
  whanauRole,
  studentRole,
  teacherRole,
  adminRole,
  systemAdminRole
} from '../Domain/Roles';

const appLayout = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-app-layout' */'../Layouts/AppLayout'));
const adminLayout = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-admin-layout' */'../Layouts/AdminLayout'));
const whanauRoleUp = {
  systemAdminRole,
  adminRole,
  teacherRole,
  studentRole,
  whanauRole
};
const studentRoleUp = {
  systemAdminRole,
  adminRole,
  teacherRole,
  studentRole
};
const studentRoleOnly = {
  studentRole
};
const teacherRoleUp = {
  systemAdminRole,
  adminRole,
  teacherRole
};
const teacherAndAdminRoleOnly = {
  adminRole,
  teacherRole
};
const adminRoleUp = {
  systemAdminRole,
  adminRole
};
const adminRoleOnly = {
  adminRole
};
const systemAdminRoleOnly = {
  systemAdminRole
};
const Routes = {
  home: {
    isActive: true,
    layout: appLayout,
    path: '/',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-home-view' */'../Views/App/HomeView')),
    isExact: true,
    isHash: false,
    clientId: 'home',
    displayName: 'Home',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  reactstrap: {
    isActive: true,
    layout: appLayout,
    path: '/reactstrap',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-reactstrap-view' */'../Views/App/ReactstrapView')),
    isExact: true,
    isHash: false,
    clientId: 'reactstrap',
    displayName: 'reactstrap',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  privacyPolicy: {
    isActive: true,
    layout: appLayout,
    path: '/privacyPolicy',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-privacy-policy-view' */'../Views/Global/PrivacyPolicyView')),
    isExact: true,
    isHash: false,
    clientId: 'privacyPolicy',
    displayName: 'Privacy Policy',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  termsOfUse: {
    isActive: true,
    layout: appLayout,
    path: '/termsOfUse',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-terms-of-use-view' */'../Views/Global/TermsOfUseView')),
    isExact: true,
    isHash: false,
    clientId: 'termsOfUse',
    displayName: 'Terms of Use',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  signIn: {
    isActive: true,
    layout: appLayout,
    path: '/signIn',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-sign-in-view' */'../Views/App/SignInView')),
    isExact: true,
    isHash: false,
    clientId: 'signIn',
    displayName: 'Sign In',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  signUp: {
    isActive: true,
    layout: appLayout,
    path: '/signUp',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-sign-up-view' */'../Views/App/SignUpView')),
    isExact: true,
    isHash: false,
    clientId: 'signUp',
    displayName: 'Sign Up',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  forgotPassword: {
    isActive: true,
    layout: appLayout,
    path: '/forgotPassword',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-forgot-password-view' */'../Views/App/ForgotPasswordView')),
    isExact: true,
    isHash: false,
    clientId: 'forgotPassword',
    displayName: 'Forgot Password',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  dashboard: {
    isActive: true,
    layout: appLayout,
    path: '/dashboard',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-dashboard-view' */'../Views/App/DashboardView')),
    isExact: true,
    isHash: false,
    clientId: 'appDashboard',
    displayName: 'App Dashboard',
    showInDashboard: false,
    dashboardGroup: 'first',
    icon: 'fas fa-home',
    count: null,
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  onboarding: {
    isActive: true,
    layout: appLayout,
    path: '/onboarding',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-onboarding-view' */'../Views/App/OnboardingView')),
    isExact: true,
    isHash: false,
    clientId: 'onboarding',
    displayName: 'Goals & Dreams',
    showInDashboard: false,
    dashboardGroup: 'first',
    icon: 'fas fa-bullseye',
    count: null,
    roles: studentRoleOnly,
    excludeFromAuthenticatedRoutes: true
  },
  journals: {
    isActive: true,
    layout: appLayout,
    path: '/journals',
    params: {
      d: 'today'
    },
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-journals-view' */'../Views/App/JournalsView')),
    isExact: true,
    isHash: false,
    clientId: 'journals',
    displayName: 'Journals',
    showInDashboard: true,
    dashboardGroup: 'first',
    icon: 'far fa-newspaper',
    count: async (state, onUserJournalsChange) => {
      const {
        authUser,
        backend
      } = state;
      const {
        uid,
        roles
      } = authUser;
      const includeInactive = false;
      const includeRead = true;
      const includeJournals = false;
      const filterStartDate = undefined;
      const filterAfterStartDate = undefined;
      const filterBeforeEndDate = undefined;
      const filterEndDate = undefined;
      const includeUnapproved = hasRoles(roles, adminRoleUp);
      const startFromTop = 0;
      const topLimit = 1000;
      return await backend
        .users
        .getUserJournalsByUidJournalJournalDate(
          uid,
          includeInactive,
          includeRead,
          includeJournals,
          filterStartDate,
          filterAfterStartDate,
          filterBeforeEndDate,
          filterEndDate,
          includeUnapproved,
          startFromTop,
          topLimit,
          onUserJournalsChange
        )
    },
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  journal: {
    isActive: true,
    layout: appLayout,
    path: '/journals/:jid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-journal-view' */'../Views/App/JournalView')),
    isExact: true,
    isHash: false,
    clientId: 'journal',
    displayName: 'Journal',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-newspaper',
    count: null,
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  notifications: {
    isActive: true,
    layout: appLayout,
    path: '/notifications',
    params: {
      d: 'today',
      c: 'All'
    },
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-notifications-view' */'../Views/App/NotificationsView')),
    isExact: true,
    isHash: false,
    clientId: 'notifications',
    displayName: 'Notifications',
    showInDashboard: true,
    dashboardGroup: 'first',
    icon: 'far fa-bell',
    count: async (state, onUserNotificationsChange) => {
      const {
        authUser,
        backend
      } = state;
      const {
        uid
      } = authUser;
      const includeInactive = false;
      const includeRead = true;
      const includeNotifications = false;
      const filterStartDate = undefined;
      const filterAfterStartDate = undefined;
      const filterBeforeEndDate = undefined;
      const filterEndDate = undefined;
      const filterCategory = undefined;
      const startFromTop = 0;
      const topLimit = 1000;
      return await backend
        .users
        .getUserNotificationsByUidNotificationCreated(
          uid,
          includeInactive,
          includeRead,
          includeNotifications,
          filterStartDate,
          filterAfterStartDate,
          filterBeforeEndDate,
          filterEndDate,
          filterCategory,
          startFromTop,
          topLimit,
          onUserNotificationsChange
        );
    },
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  profile: {
    isActive: true,
    layout: appLayout,
    path: '/profile',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-profile-view' */'../Views/App/ProfileView')),
    isExact: true,
    isHash: false,
    clientId: 'profile',
    displayName: 'Profile',
    showInDashboard: true,
    dashboardGroup: 'first',
    icon: 'fas fa-user',
    count: null,
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  adminDashboard: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/dashboard',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-admin-view' */'../Views/Admin/AdminDashboardView')),
    isExact: true,
    isHash: false,
    clientId: 'adminDashboard',
    displayName: 'Admin Dashboard',
    showInDashboard: true,
    dashboardGroup: 'second',
    icon: 'fas fa-tachometer-alt',
    count: null,
    onClick: () => window.location.href = '/admin/dashboard',
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  settings: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/settings',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-admin-view' */'../Views/Admin/SettingsView')),
    isExact: true,
    isHash: false,
    clientId: 'settings',
    displayName: 'Settings',
    showInDashboard: true,
    icon: 'fas fa-cogs',
    count: null,
    roles: systemAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  users: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/users',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-users-view' */'../Views/Admin/UsersView')),
    isExact: true,
    isHash: false,
    clientId: 'users',
    displayName: 'Users',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-users',
    count: null,
    roles: systemAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  user: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/users/:uid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-user-view' */'../Views/Admin/UserView')),
    isExact: true,
    isHash: false,
    clientId: 'user',
    displayName: 'User',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user',
    count: null,
    roles: systemAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  account: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/account',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-user-view' */'../Views/Admin/UserView')),
    isExact: true,
    isHash: false,
    clientId: 'account',
    displayName: 'Account',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user',
    count: null,
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  adminUsers: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/adminUsers',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-users-view' */'../Views/Admin/UsersView')),
    isExact: true,
    isHash: false,
    clientId: 'users',
    displayName: 'Admin Users',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user-shield',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  adminUser: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/adminUsers/:uid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-user-view' */'../Views/Admin/UserView')),
    isExact: true,
    isHash: false,
    clientId: 'user',
    displayName: 'Admin User',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user-shield',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  teachers: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/teachers',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-users-view' */'../Views/Admin/UsersView')),
    isExact: true,
    isHash: false,
    clientId: 'users',
    displayName: 'Teachers',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-chalkboard-teacher',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  teacher: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/teachers/:uid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-user-view' */'../Views/Admin/UserView')),
    isExact: true,
    isHash: false,
    clientId: 'user',
    displayName: 'Teacher',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-chalkboard-teacher',
    count: null,
    roles: adminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  students: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/students',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-users-view' */'../Views/Admin/UsersView')),
    isExact: true,
    isHash: false,
    clientId: 'users',
    displayName: 'Students',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user-graduate',
    count: null,
    roles: teacherAndAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  student: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/students/:uid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-user-view' */'../Views/Admin/UserView')),
    isExact: true,
    isHash: false,
    clientId: 'user',
    displayName: 'Student',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user-graduate',
    count: null,
    roles: teacherAndAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  whanauMembers: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/whanauMembers',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-users-view' */'../Views/Admin/UsersView')),
    isExact: true,
    isHash: false,
    clientId: 'users',
    displayName: 'Whānau',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user-friends',
    count: null,
    roles: teacherAndAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  whanauMember: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/whanauMembers/:uid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-user-view' */'../Views/Admin/UserView')),
    isExact: true,
    isHash: false,
    clientId: 'user',
    displayName: 'Whānau Member',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-user-friends',
    count: null,
    roles: teacherAndAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  reports: {
    isActive: true,
    layout: appLayout,
    path: '/reports',
    params: {
      d: 'thisMonth'
    },
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-reports-view' */'../Views/App/ReportsView')),
    isExact: true,
    isHash: false,
    clientId: 'reports',
    displayName: 'Reports',
    showInDashboard: true,
    dashboardGroup: 'second',
    icon: 'fas fa-file-alt',
    count: null,
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  faq: {
    isActive: true,
    layout: appLayout,
    path: '/faq',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-faq-view' */'../Views/App/FAQView')),
    isExact: true,
    isHash: false,
    clientId: 'faq',
    displayName: 'FAQ',
    showInDashboard: true,
    dashboardGroup: 'third',
    icon: 'fas fa-question-circle',
    count: null,
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  signOut: {
    isActive: true,
    layout: appLayout,
    path: '/signOut',
    component: null,
    isExact: true,
    isHash: true,
    clientId: 'signOut',
    displayName: 'Sign Out',
    showInDashboard: true,
    dashboardGroup: 'third',
    icon: 'fas fa-sign-out-alt',
    count: null,
    onClick: async state => await state.backend.authentication.signOut(),
    roles: whanauRoleUp,
    excludeFromAuthenticatedRoutes: true
  },
  languages: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/languages',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-languages-view' */'../Views/Admin/LanguagesView')),
    isExact: true,
    isHash: false,
    clientId: 'languages',
    displayName: 'Languages',
    showInDashboard: true,
    dashboardGroup: null,
    icon: 'fas fa-language',
    count: null,
    roles: systemAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  lunarPhases: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/lunarPhases',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-lunar-phases-view' */'../Views/Admin/LunarPhasesView')),
    isExact: true,
    isHash: false,
    clientId: 'journals',
    displayName: 'Lunar Phases',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-moon',
    count: null,
    roles: systemAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  lunarPhase: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/lunarPhases/:lpid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-lunar-phase-view' */'../Views/Admin/LunarPhaseView')),
    isExact: true,
    isHash: false,
    clientId: 'lunarPhase',
    displayName: 'Lunar Phase',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-moon',
    count: null,
    roles: systemAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  ngaPumanawaEWaruList: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/ngaPumanawaEWaruList',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-nga-pumanawa-e-waru-list-view' */'../Views/Admin/NgaPumanawaEWaruListView')),
    isExact: true,
    isHash: false,
    clientId: 'ngaPumanawaEWaruList',
    displayName: 'Ngā Pumanawa E Waru List',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-id-card',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  ngaPumanawaEWaruListItem: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/ngaPumanawaEWaruList/:npewid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-nga-pumanawa-e-waru-list-item-view' */'../Views/Admin/NgaPumanawaEWaruListItemView')),
    isExact: true,
    isHash: false,
    clientId: 'ngaPumanawaEWaruListItem',
    displayName: 'Ngā Pumanawa E Waru List Item',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-bell',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  adminReports: {
    isActive: false,
    layout: adminLayout,
    path: '/admin/adminReports',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-reports-view' */'../Views/Admin/AdminReportsView')),
    isExact: true,
    isHash: false,
    clientId: 'adminReports',
    displayName: 'Reports',
    showInDashboard: true,
    dashboardGroup: null,
    icon: 'fas fa-file-alt',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  faqList: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/faqList',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-faq-list-view' */'../Views/Admin/FAQListView')),
    isExact: true,
    isHash: false,
    clientId: 'faqList',
    displayName: 'FAQ List',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-question-circle',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  faqListItem: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/faqList/:fqid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-faq-list-item-view' */'../Views/Admin/FAQListItemView')),
    isExact: true,
    isHash: false,
    clientId: 'faqListItem',
    displayName: 'FAQ List Item',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-question-circle',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  notificationList: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/notificationList',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-notification-list-view' */'../Views/Admin/NotificationListView')),
    isExact: true,
    isHash: false,
    clientId: 'notificationList',
    displayName: 'Notification List',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-bell',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  notificationListItem: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/notificationList/:nid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-notification-list-item-view' */'../Views/Admin/NotificationListItemView')),
    isExact: true,
    isHash: false,
    clientId: 'notificationListItem',
    displayName: 'Notification List Item',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-bell',
    count: null,
    roles: adminRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  classes: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/classes',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-groups-view' */'../Views/Admin/GroupsView')),
    isExact: true,
    isHash: false,
    clientId: 'groups',
    displayName: 'Classes',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-chalkboard',
    count: null,
    roles: teacherAndAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  classesItem: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/classes/:gid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-group-view' */'../Views/Admin/GroupView')),
    isExact: true,
    isHash: false,
    clientId: 'class',
    displayName: 'Class',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'fas fa-chalkboard',
    count: null,
    roles: teacherAndAdminRoleOnly,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  groups: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/groups',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-groups-view' */'../Views/Admin/GroupsView')),
    isExact: true,
    isHash: false,
    clientId: 'groups',
    displayName: 'Groups',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-address-book',
    count: null,
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  group: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/groups/:gid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-group-view' */'../Views/Admin/GroupView')),
    isExact: true,
    isHash: false,
    clientId: 'group',
    displayName: 'Group',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-address-book',
    count: null,
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  journalList: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/journalList',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-journal-list-view' */'../Views/Admin/JournalListView')),
    isExact: true,
    isHash: false,
    clientId: 'journals',
    displayName: 'Journal List',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-newspaper',
    count: null,
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  journalListItem: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/journalList/:jid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-journal-list-item-view' */'../Views/Admin/JournalListItemView')),
    isExact: true,
    isHash: false,
    clientId: 'journalListItem',
    displayName: 'Journal List Item',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-newspaper',
    count: null,
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  reports2: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/reports',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-reports-view' */'../Views/Admin/ReportsView')),
    isExact: true,
    isHash: false,
    clientId: 'reports2',
    displayName: 'Reports',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-file-alt',
    count: null,
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: false
  },
  report2: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/reports/:rid',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-report-view' */'../Views/Admin/ReportView')),
    isExact: true,
    isHash: false,
    clientId: 'report2',
    displayName: 'Report',
    showInDashboard: false,
    dashboardGroup: null,
    icon: 'far fa-file-alt',
    count: null,
    roles: teacherRoleUp,
    excludeFromAuthenticatedRoutes: false,
    excludeFromSidebar: true
  },
  admin404: {
    isActive: true,
    layout: adminLayout,
    path: '/admin/*',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-app404-view' */'../Views/Admin/Admin404View')),
    isExact: true,
    isHash: false,
    clientId: 'admin404',
    displayName: 'Admin Global 404',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  },
  app404: {
    isActive: true,
    layout: appLayout,
    path: '',
    component: lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-app404-view' */'../Views/App/App404View')),
    isExact: true,
    isHash: false,
    clientId: 'app404',
    displayName: 'Global 404',
    showInDashboard: false,
    excludeFromAuthenticatedRoutes: true
  }
};
const hasRole = (roles, role) => !!(roles || {})[role];
const hasRoles = (roles, rolesToCheck) => { // debugger;
  let hasRolesConfirmed = false;
  Object.keys(roles).map(roleKey => {
    if (!hasRolesConfirmed) {
      hasRolesConfirmed = hasRole(rolesToCheck, roleKey);
    }
    return null;
  });
  return hasRolesConfirmed;
};

export default Routes;
export {
  hasRole,
  hasRoles,
  whanauRoleUp,
  studentRoleUp,
  studentRoleOnly,
  teacherRoleUp,
  teacherAndAdminRoleOnly,
  adminRoleUp,
  adminRoleOnly,
  systemAdminRoleOnly
}
