const mergeChartOptions = (parentChartOptions, childChartOptions) => {
  Object.keys(childChartOptions).map(childChartOptionKey => {
    parentChartOptions[childChartOptionKey] = childChartOptions[childChartOptionKey];
    return null;
  });
};

export {
  mergeChartOptions
}
