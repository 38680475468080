import React from 'react';
import Routes, {
  hasRole,
  hasRoles
} from '../../Routes';
import {
  // systemAdminRole,
  adminRole,
  teacherRole,
  studentRole,
  whanauRole,
  // undefinedRole
} from '../../Domain/Roles';
import {
  groupType,
  classType
} from '../../Domain/GroupTypes';
import {
  lowEnergyLevel,
  passiveEnergyLevel,
  moderateEnergyLevel,
  highEnergyLevel,
  highestEnergyLevel
} from '../../Domain/EnergyLevels';
import {
  beginningPumanawaLevel,
  developingPumanawaLevel,
  integratedPumanawaLevel
} from '../../Domain/PumanawaLevels';
import {
  toMoment,
  isDate,
  isBetweenDates,
  toDate,
  toEndOfDayMoment,
  toFormattedDate,
  toFormattedDateTime,
  toTimeAgo,
  toFormattedEndOfDayDateTime
} from './date';
import {
  DATE_MOMENT_FORMAT,
  DATE_TIME_MOMENT_FORMAT,
  ISO8601_DATE_FORMAT,
  ISO8601_DATE_TIME_FORMAT,
  JOURNAL_DATE_FORMAT
} from '../constants/date';
import {
  customSwalMixin,
  customSwal
} from './customSwal';
import {
  withSuspense
} from './withSuspense';
import {
  useStateWithPromise
} from './useStateWithPromise';
import {
  generateFirebaseStorageFileLocation,
  isFirebaseStorageFileLocation,
  getFirebaseAudioFileLocation,
  isFirebaseAudioFileLocation,
  getFirebaseVideoFileLocation,
  isFirebaseVideoFileLocation,
  getFirebaseImageFileLocation,
  isFirebaseImageFileLocation,
  generateFirebaseStorageFileUrl,
  getFirebaseStorageFileType,
  generateFirebaseStorageFileLocationFormat,
  getFirebaseStorageUrl
} from './FirebaseStorageFileTypes';
import {
  getDefaultToolbar,
  getOverrideToolbar
} from './overrideToolbar';
import {
  isContentEmptyAsync,
  isContentEmpty
} from './draftEditor';
import {
  mergeChartOptions
} from './charts';
import {
  toEnergyLevelDisplayName
} from '../functions/energyLevels';
import {
  toPumanawaLevelDisplayName
} from '../functions/pumanawaLevels';
import {
  getFilterDates
} from '../functions/filterDates';
import generateFirebaseId from '../functions/generateFirebaseId';

const isEmptyString = value => value === '';
const isNullOrEmpty = value => value == null || isEmptyString(value);
const isTrueOrFalse = value => {
  const valueAsLowercaseString = (value || '').toString().toLowerCase();
  return valueAsLowercaseString === 'true' || valueAsLowercaseString === 'false';
};
const isBoolean = (value, expectedValue = undefined) =>
  !isNullOrEmpty(value) &&
  (typeof value === 'boolean' || isTrueOrFalse(value)) &&
  (isNullOrEmpty(expectedValue) || value.toString().toLowerCase() === expectedValue.toString().toLowerCase());
const getNewState = (state, nameParts, index, value) => {
  const namePart = nameParts[index];
  const stateToUse = index === 0
    ? state
    : {};
  const isLastNamePart = index === (nameParts.length - 1);
  const property = state[namePart]
    ? state[namePart]
    : {};
  const newValue = isLastNamePart
    ? value !== property ? value : property
    : {
      ...property,
      ...getNewState(property, nameParts, (index + 1), value)
    };
  const newState = {
    ...stateToUse,
    [namePart]: newValue
  }; // debugger;
  return newState;
};
const handleFieldChange = (e, setState, isCheckedOrCallbackFunction = undefined) => { // debugger;
  const {
    name,
    value,
    checked,
    options
  } = e.target;
  const isCheckedOrCallbackFunctionType = typeof isCheckedOrCallbackFunction;
  const isChecked = isCheckedOrCallbackFunctionType === 'boolean' && isCheckedOrCallbackFunction === true;
  const isCallbackFunction = isCheckedOrCallbackFunctionType === 'function';
  const getSelectedOption = options => {
    const filteredOptions = Object
      .keys(options)
      .map(optionKey =>
        options[optionKey].selected && options[optionKey].value
      )
      .filter(v =>
        v !== false
      );
    return filteredOptions.length === 1
      ? filteredOptions[0]
      : filteredOptions;
  };
  const valueToUse = options
    ? getSelectedOption(options)
    : isChecked
      ? checked
      : value;
  const nameParts = name.split('.');
  setState(s => getNewState(s, nameParts, 0, valueToUse));
  if (isCallbackFunction) {
    isCheckedOrCallbackFunction(e);
  }
};
const isEmailValid = email => !isNullOrEmpty(email) && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
const isPasswordValid = password => !isNullOrEmpty(password) && password.match(/(?:(?:(?=.*?[0-9])(?=.*?[-!@#$%&*ˆ+=_])|(?:(?=.*?[0-9])|(?=.*?[A-Z])|(?=.*?[-!@#$%&*ˆ+=_])))|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-!@#$%&*ˆ+=_]))[A-Za-z0-9-!@#$%&*ˆ+=_]{6,15}/);
const isDisplayNameValid = displayName => !isNullOrEmpty(displayName) && displayName.length > 1 && !displayName.match("[0-9]+");
const isUrlValid = url => !isNullOrEmpty(url) && url.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
const fromCamelcaseToTitlecase = camelCase => camelCase.replace(/([A-Z])/g, match => ` ${match}`).replace(/^./, match => match.toUpperCase());
const toCamelcase = value => {
  return value.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) {
      return '';
    }
    return index === 0
      ? match.toLowerCase()
      : match.toUpperCase();
  });
};
const isJson = jsonAsString => typeof toJson(jsonAsString, 1) === 'object';
const toJson = (jsonAsString, defaultReturnValue = {}) => {
  try {
    return JSON.parse(jsonAsString);
  } catch {
    return defaultReturnValue;
  }
};
const stripHtml = html => html.replace(/(<([^>]+)>)/ig, '');
const getFirstCharacters = (value, length, excludeEllipse = false) => {
  return value && value.length > length
    ? `${value.substring(0, length - (excludeEllipse ? 0 : 3))}${!excludeEllipse && '...'}`
    : value;
};
const draftToText = async (draftRaw, defaultValue = undefined, maxLength = undefined) => {
  if (!isJson(draftRaw)) {
    return defaultValue !== undefined
      ? defaultValue
      : draftRaw;
  }
  const draftAsJson = toJson(draftRaw);
  const {
    default: draftToHtml
  } = await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-draftjs-to-html' */'draftjs-to-html');
  const draftAsHtml = draftToHtml(draftAsJson);
  const draftAsText = stripHtml(draftAsHtml);
  return isNumber(maxLength)
    ? getFirstCharacters(draftAsText, maxLength, false)
    : draftAsText;
};
const isNumber = value => value && !isNaN(value);
const tryToConvertValue = value => {
  let convertedValue = undefined;
  let convertedValueType = undefined;
  if (isNumber(value)) {
    convertedValue = Number(value);
  } else if (isBoolean(value)) {
    convertedValue = Boolean(value);
  } else if (isDate(value)) {
    convertedValue = toDate(value);
  } else {
    convertedValue = value;
  }
  convertedValueType = typeof convertedValue;
  return {
    convertedValue,
    convertedValueType
  };
};
const getValue = (object, key = undefined, keySeparator = '.') => {
  let value = object;
  if (typeof key === 'string') {
    const keyParts = key.split(keySeparator);
    if (keyParts.length === 1) {
      value = object[key]
    } else {
      const objectKey = keyParts.slice(0, keyParts.length - 1).join(keySeparator);
      const fieldKey = keyParts.slice(1).join(keySeparator);
      value = getValue(object[objectKey], fieldKey);
    }
  }
  return value;
};
const handleSort = (a, b, sortOrder = 'asc', sortName = undefined, sortNameSeparator = '.') => {
  const {
    convertedValue: aValue,
    convertedValueType: aValueType
  } = tryToConvertValue(getValue(a, sortName, sortNameSeparator));
  const {
    convertedValue: bValue,
    convertedValueType: bValueType
  } = tryToConvertValue(getValue(b, sortName, sortNameSeparator)); // debugger;
  const result = sortOrder === 'asc'
    ? aValueType === 'number' && bValueType === 'number'
      ? bValue - aValue
      : aValue > bValue
        ? 1
        : aValue < bValue
          ? -1
          : 0
    : aValueType === 'number' && bValueType === 'number'
      ? aValue - bValue
      : bValue > aValue
        ? 1
        : bValue < aValue
          ? -1
          : 0;
  // console.log('array-sort: ', JSON.stringify({ sortName, sortOrder, a, aValue, aValueType, b, bValue, bValueType, result }, null, 2));
  return result;
};
const sortArray = (array, sortName, sortOrder) => {
  // console.log('array-before: ', JSON.stringify(array.map(item => `${sortName}: ${getValue(item, sortName)}`), null, 2));
  array.sort((a, b) => handleSort(a, b, sortOrder, sortName));
  // console.log('array-after: ', JSON.stringify(array.map(item => `${sortName}: ${getValue(item, sortName)}`), null, 2));
};
const handleCaret = (order, column) => {
  let iconName;
  switch (order) {
    case 'asc':
      iconName = 'fa-sort-up';
      break;
    case 'desc':
      iconName = 'fa-sort-down';
      break;
    default:
      iconName = 'fa-sort';
  }
  // console.log(`handleCaret.column: ${JSON.stringify(column, null, 2)}`);
  return (
    <i className={`react-bootstrap-table-caret ${column.dataField} fas ${iconName}`} />
  );
};
const callFunction = async (backend, baseUrl, functionName, data) => {
  const functionsRepositoryOptions = {
    baseUrl,
    functionName,
    data
  };
  const result = await backend.functions.call(functionsRepositoryOptions);
  console.log(`${functionsRepositoryOptions.functionName}.result: ${JSON.stringify(result, null, 2)}`);
  return result && result.data
    ? result.data
    : {
      success: false,
      message: '',
      data: null
    };
};
const getDbItem = async (backend, dbObjectsName, id) => {
  const dbItem = await backend[dbObjectsName].getDbItemValue(id);
  if (dbItem) {
    return dbItem;
  }
  console.log(`${dbObjectsName} Id '${id}' not found.`);
  return null;
};
const getUser = async (backend, uid) => {
  return await getDbItem(backend, 'users', uid);
};
const getUserDisplayName = async (backend, uid) => {
  const dbUser = await getUser(backend, uid);
  if (dbUser) {
    return dbUser.displayName;
  }
  return 'UNKNOWN';
};
const generateResourceBundle = async (content, language) => {
  const resourceBundle = {};
  if (content) {
    await Promise.all(Object.keys(content).map(async contentKey => {
      let jsonNode = content[contentKey];
      const {
        en,
        mi
      } = jsonNode;
      if (typeof en !== 'undefined' && typeof mi !== 'undefined') {
        jsonNode = language === 'en'
          ? en
          : mi;
      }
      if (typeof jsonNode === 'string') {
        resourceBundle[contentKey] = jsonNode;
      } else {
        resourceBundle[contentKey] = await generateResourceBundle(jsonNode, language);
      }
    }));
  }
  return resourceBundle;
};
const constructResourceBundles = async (content, supportedLanguages) => {
  const resourceBundles = {};
  await Promise.all(Object.keys(supportedLanguages || {}).map(async supportedLanguageKey => { // debugger;
    const resourceBundle = await generateResourceBundle(content, supportedLanguageKey);
    resourceBundles[supportedLanguageKey] = resourceBundle;
    // i18n.addResourceBundle(supportedLanguageKey, 'common', resourceBundle);
    // console.log(`LanguageProvider.handleDbItemValueChange.i18n.addResourceBundle: ${supportedLanguageKey},\nresourceBundle: ${JSON.stringify(resourceBundle, null, 2)}`);
    // console.log(`LanguageProvider.handleDbItemValueChange.i18n.addResourceBundle: ${supportedLanguageKey}`);
    return null;
  }));
  return resourceBundles;
};
const deconstructResource = (content, supportedLanguageKey, newContent) => {
  for (const [key, value] of Object.entries(content)) {
    newContent[key] = newContent[key] || {};
    if (typeof value === 'object') {
      deconstructResource(value, supportedLanguageKey, newContent[key]);
    } else {
      newContent[key] = {
        ...newContent[key],
        [supportedLanguageKey]: value
      };
    }
  }
};
const deconstructResourceBundles = (content, supportedLanguages) => {
  let newContent = {};
  Object.keys(supportedLanguages || {}).map(supportedLanguageKey =>
    deconstructResource(content[supportedLanguageKey], supportedLanguageKey, newContent)
  );
  return newContent;
};
const groupBy = (array, key) => {
  const groupByReducer = (accumulator, currentValue, index, array, keyOverride) => {
    const keyParts = key.split('.');
    const currentValueKey = currentValue[keyOverride || keyParts[0]];
    if (typeof currentValueKey === 'object') {
      Object
        .keys(currentValueKey)
        .filter(k =>
          keyParts.length === 1 || k === keyParts[1]
        ).map(k => {
          if (keyParts.length === 1) {
            (accumulator[k] = accumulator[k] || []).push(currentValue);
          } else if (k === keyParts[1]) {
            (accumulator[k] = accumulator[k] || []).push(currentValue[keyParts[0]][keyParts[1]]);
          }
          return null;
        });
    } else {
      (accumulator[currentValueKey] = accumulator[currentValueKey] || []).push(currentValue);
    }
    return accumulator;
  };
  const groupedBy = array.reduce(groupByReducer, {});
  return groupedBy;
};
const downloadBlob = async (blob, fileName) => {
  const {
    body
  } = document;
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  body.appendChild(link);
  link.click();
  body.removeChild(link);
};
const {
  user,
  users,
  adminUser,
  adminUsers,
  teacher,
  teachers,
  student,
  students,
  whanauMember,
  whanauMembers,
  group,
  groups,
  classesItem,
  classes,
  dashboard,
  adminDashboard,
  account
} = Routes;
const getActiveRouteDetails = () => { // debugger;
  const {
    pathname
  } = window.location;
  const pathnameParts = pathname.split('/');
  const pathnameId = pathnameParts.pop();
  let activeRoute, activeRoutes, activeRole, activeType;
  if (pathnameParts.length === 2) {
    activeRoutes = adminDashboard;
    switch (pathname) {
      case adminUsers.path:
        activeRoute = adminUsers;
        activeRole = adminRole;
        break;
      case teachers.path:
        activeRoute = teachers;
        activeRole = teacherRole;
        break;
      case students.path:
        activeRoute = students;
        activeRole = studentRole;
        break;
      case whanauMembers.path:
        activeRoute = whanauMembers;
        activeRole = whanauRole;
        break;
      case groups.path:
        activeRoute = groups;
        activeType = groupType;
        break;
      case classes.path:
        activeRoute = classes;
        activeType = classType;
        break;
      case account.path:
        activeRoute = account;
        break;
      case users.path:
      default:
        activeRoute = users;
        activeRole = adminRole;
        break;
    }
  } else {
    switch (pathname) {
      case adminUser.path.replace(':uid', pathnameId):
        activeRoute = adminUser;
        activeRoutes = adminUsers;
        activeRole = adminRole;
        break;
      case teacher.path.replace(':uid', pathnameId):
        activeRoute = teacher;
        activeRoutes = teachers;
        activeRole = teacherRole;
        break;
      case student.path.replace(':uid', pathnameId):
        activeRoute = student;
        activeRoutes = students;
        activeRole = studentRole;
        break;
      case whanauMember.path.replace(':uid', pathnameId):
        activeRoute = whanauMember;
        activeRoutes = whanauMembers;
        activeRole = whanauRole;
        break;
      case group.path.replace(':gid', pathnameId):
        activeRoute = group;
        activeRoutes = groups;
        activeType = groupType;
        break;
      case classesItem.path.replace(':gid', pathnameId):
        activeRoute = classesItem;
        activeRoutes = classes;
        activeType = classType;
        break;
      case user.path.replace(':uid', pathnameId):
      default:
        activeRoute = user;
        activeRoutes = users;
        activeRole = adminRole;
        break;
    }
  }
  return {
    activeRoute,
    activeRoutes,
    activeRole,
    activeType
  };
};
const SuspenseFallback = () => <div className="pumahara-loading" />;
const Nz = (value, ifNullValue = undefined) => {
  return isNullOrEmpty(value)
    ? typeof ifNullValue === 'undefined'
      ? ''
      : ifNullValue
    : value;
};
const refactorObject = rest => {
  const refactoredObject = {};
  Object.keys(rest).map(k => {
    const refactoredValue = Nz(rest[k], null);
    if (refactoredValue !== null) {
      refactoredObject[k] = refactoredValue;
    }
    return null;
  });
  return refactoredObject;
};
const getParamsAsString = params => {
  return params
    ? `?${Object
      .keys(params)
      .map(key =>
        `${key}=${encodeURIComponent(params[key])}`)
      .join('&')}`
    : '';
};
const reduceArrayOfArrays = array => {
  const reducedArray = [].concat(...(array || []));
  return reducedArray;
};
const dedupe = array => {
  const dedupedArray = [...new Set(array || [])];
  return dedupedArray;
};
const asyncFilter = async (array, predicate) => Promise.all(array.map(predicate)).then(predicateResults => array.filter((v, index) => predicateResults[index]));
const handleButtonClick = async (e, history, path = dashboard.path) => { // debugger;
  e.preventDefault();
  history.push(path);
};
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const toRoleDisplayName = role => {
  let displayName = fromCamelcaseToTitlecase(role)
    .replace(' Role', '')
    .replace('Whanau', 'Whānau')
  return displayName;
};
const getUsePromiseTrackerConfig = (area, delay) => {
  const {
    REACT_APP_PROMISE_TRACKER_AREA,
    REACT_APP_PROMISE_TRACKER_DELAY
  } = process.env;
  const DEFAULT_PROMISE_TRACKER_AREA = '';
  const DEFAULT_PROMISE_TRACKER_DELAY = 300;
  const usePromiseTrackerConfig = {
    area: area || REACT_APP_PROMISE_TRACKER_AREA || DEFAULT_PROMISE_TRACKER_AREA,
    delay: delay || REACT_APP_PROMISE_TRACKER_DELAY || DEFAULT_PROMISE_TRACKER_DELAY
  };
  return usePromiseTrackerConfig;
};
const updateSingleDbField = async (e, saveMethod, row, fieldName, fieldDisplayName, setState, updatedBy, postSaveMethod = undefined) => { // debugger;
  e.preventDefault();
  e.stopPropagation();
  const generateNewState = async (saveMethodResult, postSaveMethod) => { // debugger;
    const newState = {
      isLoading: true
    };
    if (typeof postSaveMethod === 'function') { // debugger;
      const postSaveMethodResult = await postSaveMethod(saveMethodResult);
      Object.keys(postSaveMethodResult).map(postSaveMethodResultKey =>
        newState[postSaveMethodResultKey] = postSaveMethodResult[postSaveMethodResultKey]
      );
    }
    return newState;
  };
  let saveMethodResult = null;
  try {
    saveMethodResult = await saveMethod({
      ...row,
      [fieldName]: !row[fieldName],
      updated: toFormattedDateTime(new Date(), DATE_TIME_MOMENT_FORMAT, ISO8601_DATE_TIME_FORMAT),
      updatedBy,
      version: row.version
    });
    const newState = await generateNewState(saveMethodResult, postSaveMethod);
    setState(s => ({
      ...s,
      ...newState
    }));
  } catch (error) {
    customSwal({
      icon: 'error',
      title: `Update ${fieldDisplayName} Error`,
      text: error.message
    });
  }
  return saveMethodResult;
};
const saveAuthUser = async (e, functionCallMethod, row, setState, authUserUid, postFunctionCallMethod = undefined) => {
  e.preventDefault();
  e.stopPropagation();
  try {
    const {
      REACT_APP_GOOGLE_BASE_CLOUD_FUNCTIONS_URL: GCF_URL
    } = process.env;
    const {
      uid,
      emailVerified,
      version
    } = row;
    const functionsRepositoryOptions = {
      baseUrl: GCF_URL,
      functionName: 'saveAuthUser',
      data: {
        authUser: {
          uid,
          emailVerified: !emailVerified,
          updated: toFormattedDateTime(new Date(), DATE_TIME_MOMENT_FORMAT, ISO8601_DATE_TIME_FORMAT),
          updatedBy: authUserUid,
          version
        }
      }
    };
    const result = await functionCallMethod(functionsRepositoryOptions);
    // console.log(`${functionsRepositoryOptions.functionName}.result: ${JSON.stringify(result, null, 2)}`);
    const newState = await postFunctionCallMethod(uid);
    setState(s => ({
      ...s,
      ...newState
    }));
    return result;
  } catch (error) {
    customSwal({
      icon: 'error',
      title: `Update Approval Error`,
      text: error.message
    });
  }
};
const postFunctionCallMethod = async (uid, dbUsers, getDbUserValue) => { // debugger;
  const newState = {
    isLoading: true
  };
  const dbUserIndex = dbUsers.findIndex(dbUser => dbUser.uid === uid);
  if (dbUserIndex > -1) {
    const dbUser = await getDbUserValue(uid);
    dbUsers[dbUserIndex] = dbUser;
    newState.dbUsers = dbUsers;
  }
  return newState;
};
const getSingleDbItemValue = async (dbRepository, primaryKey) => {
  const dbItem = await dbRepository.getDbItemValue(primaryKey);
  return dbItem;
};
const getSingleDbFieldValue = async (dbRepository, primaryKey, fieldName) => {
  const dbItem = await getSingleDbItemValue(dbRepository, primaryKey);
  return dbItem && dbItem[fieldName];
};
const getSize = imageResize => {
  let size = null;
  switch ((imageResize || '')) {
    case 'sm':
      size = 150;
      break;
    case 'md':
      size = 400;
      break;
    case 'lg':
      size = 768;
      break;
    default:
      size = NaN;
  }
  return size;
};
const getImageUrlToUse = (imageResizeOrSize, imageUrl) => {
  let imageUrlToUse = imageUrl;
  const size = isNumber(imageResizeOrSize)
    ? Number(imageResizeOrSize)
    : getSize(imageResizeOrSize); // debugger;
  const {
    dirname
  } = require('path');
  const bucketFolder = dirname(imageUrl);
  const fileName = imageUrl.split('/').pop();
  const ext = fileName.split('.').pop();
  const imgName = fileName.replace(`.${ext}`, '');
  if (bucketFolder && imgName) {
    imageUrlToUse = `${bucketFolder}/${imgName}${isNumber(size) ? `@s_${size}` : ''}.webp`;
  }
  return imageUrlToUse;
};
const formatBytes = (bytes, dp = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const oneKiloByte = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(oneKiloByte));
  const fractionDigits = dp < 0
    ? 0
    : dp;
  const refactoredBytes = parseFloat((bytes / Math.pow(oneKiloByte, i)).toFixed(fractionDigits));
  const formattedBytes = `${refactoredBytes} ${sizes[i]}`;
  return formattedBytes;
};
const formatInteger = (integer) => (
  integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
);
const getColorForEnergyLevel = (energyLevel, isForApp = false) => {
  switch (energyLevel) {
    case passiveEnergyLevel:
      return isForApp
        ? 'secondary'
        : 'light';
    case moderateEnergyLevel:
      return 'success';
    case highEnergyLevel:
      return 'primary';
    case highestEnergyLevel:
      return 'danger';
    case lowEnergyLevel:
    default:
      return 'info';
  }
};
const getColorForPumanawaLevel = (pumanawaLevel) => {
  switch (pumanawaLevel) {
    case integratedPumanawaLevel:
      return 'success';
    case developingPumanawaLevel:
      return 'warning';
    case beginningPumanawaLevel:
    default:
      return 'info';
  }
};
const generateFormattedDate = (dateAsString, dateFormat, dateFormatOverride, addEndOfDayTime = false, asMoment = false) => {
  if (!isDate(dateAsString, dateFormat)) {
    return undefined;
  }
  const dateAsMoment = addEndOfDayTime
    ? toEndOfDayMoment(dateAsString, dateFormat)
    : toMoment(dateAsString, dateFormat);
  if (asMoment) {
    return dateAsMoment;
  }
  const dateAsFormattedDate = dateAsMoment.format(dateFormatOverride);
  return dateAsFormattedDate;
};
const generateFormattedDates = (dateAsString, addEndOfDayTime = false, asMoments = false) => {
  const dateAsIso8601Date = generateFormattedDate(dateAsString, ISO8601_DATE_FORMAT, ISO8601_DATE_TIME_FORMAT, addEndOfDayTime, asMoments);
  const dateAsIso8601DateTime = generateFormattedDate(dateAsString, ISO8601_DATE_TIME_FORMAT, ISO8601_DATE_TIME_FORMAT, addEndOfDayTime, asMoments);
  const dateAsStandardDate = generateFormattedDate(dateAsString, DATE_MOMENT_FORMAT, ISO8601_DATE_TIME_FORMAT, addEndOfDayTime, asMoments);
  const dateAsStandardDateTime = generateFormattedDate(dateAsString, DATE_TIME_MOMENT_FORMAT, ISO8601_DATE_TIME_FORMAT, addEndOfDayTime, asMoments);
  const dateAsJournalDateTime = generateFormattedDate(dateAsString, JOURNAL_DATE_FORMAT, ISO8601_DATE_TIME_FORMAT, addEndOfDayTime, asMoments);
  const formattedDates = {
    dateAsIso8601Date,
    dateAsIso8601DateTime,
    dateAsStandardDate,
    dateAsStandardDateTime,
    dateAsJournalDateTime
  };
  return formattedDates;
};
const getFormattedDate = (dateAsString, addEndOfDayTime = false, asMoments = false) => {
  const {
    dateAsIso8601Date,
    dateAsIso8601DateTime,
    dateAsStandardDate,
    dateAsStandardDateTime,
    dateAsJournalDateTime
  } = generateFormattedDates(dateAsString, addEndOfDayTime, asMoments);
  const dateAsFormattedDate = dateAsIso8601Date || dateAsIso8601DateTime || dateAsStandardDate || dateAsStandardDateTime || dateAsJournalDateTime;
  return dateAsFormattedDate;
};
const generatePrimaryKeyDate = (primaryKey, dateAsString, returnValueIfInvalid = undefined, hasDateFilter = false, addEndOfDayTime = false) => {
  const dateAsFormattedDate = getFormattedDate(dateAsString, addEndOfDayTime);
  const dateAsPrimaryKeyDate = !isNullOrEmpty(dateAsFormattedDate) || !hasDateFilter
    ? `${primaryKey}_${dateAsFormattedDate || ''}`
    : returnValueIfInvalid;
  return dateAsPrimaryKeyDate;
};
const generatePrimaryKeyDates = (
  uid,
  filterStartDate = undefined,
  filterAfterStartDate = undefined,
  filterBeforeEndDate = undefined,
  filterEndDate = undefined
) => {
  const hasFilterStartDate = !isNullOrEmpty(filterStartDate);
  const hasFilterAfterStartDate = !isNullOrEmpty(filterAfterStartDate);
  const hasFilterStartDateOrFilterAfterStartDate = !hasFilterStartDate || !hasFilterAfterStartDate;
  const hasFilterBeforeEndDate = !isNullOrEmpty(filterBeforeEndDate);
  const hasFilterEndDate = !isNullOrEmpty(filterEndDate);
  const hasFilterBeforeEndDateOrFilterEndDate = !hasFilterBeforeEndDate || !hasFilterEndDate;
  const childStartAtValue = generatePrimaryKeyDate(uid, filterStartDate, undefined, hasFilterStartDateOrFilterAfterStartDate);
  const childStartAfterValue = generatePrimaryKeyDate(uid, filterAfterStartDate, undefined, hasFilterStartDate);
  const childEndBeforeValue = generatePrimaryKeyDate(uid, filterBeforeEndDate, undefined, hasFilterEndDate, true);
  const childEndAtValue = generatePrimaryKeyDate(uid, filterEndDate, undefined, hasFilterBeforeEndDateOrFilterEndDate, true);
  return {
    childStartAtValue,
    childStartAfterValue,
    childEndBeforeValue,
    childEndAtValue
  };
};
const generateFiltersAsMoments = (
  filterStartDate = undefined,
  filterAfterStartDate = undefined,
  filterBeforeEndDate = undefined,
  filterEndDate = undefined
) => {
  const filterStartDateAsMoment = getFormattedDate(filterStartDate, false, true);
  const filterAfterStartDateAsMoment = getFormattedDate(filterAfterStartDate, false, true);
  const filterBeforeEndDateAsMoment = getFormattedDate(filterBeforeEndDate, true, true);
  const filterEndDateAsMoment = getFormattedDate(filterEndDate, true, true);
  const filtersAsMoments = {
    filterStartDateAsMoment,
    filterAfterStartDateAsMoment,
    filterBeforeEndDateAsMoment,
    filterEndDateAsMoment
  };
  return filtersAsMoments;
};
const areYouSure = async (t) => {
  const {
    isConfirmed
  } = await customSwal({
    icon: 'warning',
    title: t('app.common.areYouSure'),
    text: t('app.common.youWontBeAbleToUndoThis'),
    confirmButtonText: t('app.common.yes'),
    cancelButtonText: t('app.common.no'),
    showCancelButton: true
  });
  return isConfirmed;
};

export {
  isEmptyString,
  isNullOrEmpty,
  isTrueOrFalse,
  isBoolean,
  getNewState,
  handleFieldChange,
  isEmailValid,
  isPasswordValid,
  isDisplayNameValid,
  isUrlValid,
  fromCamelcaseToTitlecase,
  toCamelcase,
  hasRole,
  hasRoles,
  isJson,
  toJson,
  stripHtml,
  getFirstCharacters,
  draftToText,
  isNumber,
  tryToConvertValue,
  handleCaret,
  handleSort,
  getValue,
  sortArray,
  callFunction,
  getDbItem,
  getUser,
  getUserDisplayName,
  generateResourceBundle,
  constructResourceBundles,
  deconstructResourceBundles,
  groupBy,
  downloadBlob,
  getActiveRouteDetails,
  SuspenseFallback,
  Nz,
  refactorObject,
  getParamsAsString,
  reduceArrayOfArrays,
  dedupe,
  asyncFilter,
  handleButtonClick,
  sleep,
  toRoleDisplayName,
  getUsePromiseTrackerConfig,
  updateSingleDbField,
  saveAuthUser,
  postFunctionCallMethod,
  getSingleDbItemValue,
  getSingleDbFieldValue,
  getSize,
  getImageUrlToUse,
  formatBytes,
  formatInteger,
  getColorForEnergyLevel,
  getColorForPumanawaLevel,
  generateFormattedDate,
  generateFormattedDates,
  getFormattedDate,
  generatePrimaryKeyDate,
  generatePrimaryKeyDates,
  generateFiltersAsMoments,
  areYouSure,

  toMoment,
  isDate,
  isBetweenDates,
  toDate,
  toEndOfDayMoment,
  toFormattedDate,
  toFormattedDateTime,
  toTimeAgo,
  toFormattedEndOfDayDateTime,

  customSwalMixin,
  customSwal,

  withSuspense,

  useStateWithPromise,

  generateFirebaseStorageFileLocation,
  isFirebaseStorageFileLocation,
  getFirebaseAudioFileLocation,
  isFirebaseAudioFileLocation,
  getFirebaseVideoFileLocation,
  isFirebaseVideoFileLocation,
  getFirebaseImageFileLocation,
  isFirebaseImageFileLocation,
  generateFirebaseStorageFileUrl,
  getFirebaseStorageFileType,
  generateFirebaseStorageFileLocationFormat,
  getFirebaseStorageUrl,

  getDefaultToolbar,
  getOverrideToolbar,

  isContentEmptyAsync,
  isContentEmpty,

  mergeChartOptions,

  toEnergyLevelDisplayName,
  toPumanawaLevelDisplayName,

  getFilterDates,

  generateFirebaseId
}
