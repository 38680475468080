import React, {
  lazy
} from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const Col = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-reactstrap-col' */'reactstrap/es/Col'));
const FormGroup = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-reactstrap-form-group' */'reactstrap/es/FormGroup'));
const CustomInput = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-reactstrap-custom-input' */'reactstrap/es/CustomInput'));
const Input = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-reactstrap-input' */'reactstrap/es/Input'));
const Label = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-reactstrap-label' */'reactstrap/es/Label'));
const RoundedPillInputWithIcon = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-rounded-pill-input-wth-icon' */'../RoundedPillInputWithIcon'));
const RoundedPillInput = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-rounded-pill-input' */'../RoundedPillInput'));
const RoundedPillButton = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-rounded-pill-button' */'../RoundedPillButton'));
const RoundedPillSelect = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-rounded-pill-select' */'../RoundedPillSelect'));
const RoundedPillDateTime = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-rounded-pill-date-time' */'../RoundedPillDateTime'));
const DraftEditor = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-draft-editor' */'../DraftEditor'));
const inputTypes = {
  input: 'Input',
  customInput: 'CustomInput',
  roundedPillInput: 'RoundedPillInput',
  roundedPillInputWithIcon: 'RoundedPillInputWithIcon',
  roundedPillButton: 'RoundedPillButton',
  roundedPillSelect: 'RoundedPillSelect',
  roundedPillDateTime: 'RoundedPillDateTime',
  draftEditor: 'DraftEditor',
  children: 'Children'
};
const FormGroupRow = props => {
  const {
    formGroupRowClassName,
    primaryInputProps,
    secondaryInputProps,
    tertiaryInputProps
  } = props;
  const {
    primaryInputType,
    primaryLabelHtml,
    primaryLabelText,
    primaryLabelSm,
    primaryInputSm,
    primarySm,
    primaryColClassName,
    primaryChildren,
    id: primaryId,
    ...primaryRest
  } = primaryInputProps;
  const getInputControl = inputType => {
    const InputControl = inputType === inputTypes.customInput
      ? CustomInput
      : inputType === inputTypes.roundedPillInput
        ? RoundedPillInput
        : inputType === inputTypes.roundedPillInputWithIcon
          ? RoundedPillInputWithIcon
          : inputType === inputTypes.roundedPillButton
            ? RoundedPillButton
            : inputType === inputTypes.roundedPillSelect
              ? RoundedPillSelect
              : inputType === inputTypes.roundedPillDateTime
                ? RoundedPillDateTime
                : inputType === inputTypes.draftEditor
                  ? DraftEditor
                  : inputType === inputTypes.children
                    ? null
                    : Input;
    return InputControl;
  };
  const PrimaryInputControl = getInputControl(primaryInputType);
  const {
    secondaryInputType,
    secondaryLabelHtml,
    secondaryLabelText,
    secondaryLabelSm,
    secondaryInputSm,
    secondarySm,
    secondaryColClassName,
    secondaryChildren,
    id: secondaryId,
    ...secondaryRest
  } = (secondaryInputProps || {});
  const SecondaryInputControl = getInputControl(secondaryInputType);
  const {
    tertiaryInputType,
    tertiaryLabelHtml,
    tertiaryLabelText,
    tertiaryLabelSm,
    tertiaryInputSm,
    tertiarySm,
    tertiaryColClassName,
    tertiaryChildren,
    id: tertiaryId,
    ...tertiaryRest
  } = (tertiaryInputProps || {});
  const TertiaryInputControl = getInputControl(tertiaryInputType);
  return (
    <>
      <FormGroup row className={formGroupRowClassName}>
        {
          primaryLabelSm || primarySm
            ? <Label for={primaryId} xs={12} sm={primaryLabelSm || primarySm}>{primaryLabelHtml || primaryLabelText}</Label>
            : null
        }
        <Col xs={12} sm={primaryInputSm || primarySm} className={primaryColClassName}>
          {
            PrimaryInputControl
              ? <PrimaryInputControl
                id={primaryId}
                {...primaryRest}
              />
              : primaryChildren
          }
        </Col>
        {
          secondaryInputProps
            ? <>
              {
                secondaryLabelSm || secondarySm
                  ? <Label for={secondaryId} xs={12} sm={secondaryLabelSm || secondarySm}>{secondaryLabelHtml || secondaryLabelText}</Label>
                  : null
              }
              <Col xs={12} sm={secondaryInputSm || secondarySm} className={secondaryColClassName}>
                {
                  SecondaryInputControl
                    ? <SecondaryInputControl
                      id={secondaryId}
                      {...secondaryRest}
                    />
                    : secondaryChildren
                }
              </Col>
            </>
            : null
        }
        {
          tertiaryInputProps
            ? <>
              {
                tertiaryLabelSm || tertiarySm
                  ? <Label for={tertiaryId} xs={12} sm={tertiaryLabelSm || tertiarySm}>{tertiaryLabelHtml || tertiaryLabelText}</Label>
                  : null
              }
              <Col xs={12} sm={tertiaryInputSm || tertiarySm} className={tertiaryColClassName}>
                {
                  TertiaryInputControl
                    ? <TertiaryInputControl
                      id={tertiaryId}
                      {...tertiaryRest}
                    />
                    : tertiaryChildren
                }
              </Col>
            </>
            : null
        }
      </FormGroup>
    </>
  );
}

FormGroupRow.propTypes = {
  primaryInputProps: PropTypes.object.isRequired,
  secondaryInputProps: PropTypes.object
};
FormGroupRow.defaultProps = {
};

export default FormGroupRow;
export {
  inputTypes
};
