import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import LoadingIcon from '../LoadingIcon';
import usePastDelay from 'react-lazy-no-flicker/es/usePastDelay';

const LoadingSpinner = props => {
  const {
    caller,
    outerClassName,
    iconOuterClassName,
    iconClassName,
    iconTextClassName,
    color,
    text,
    delay,
    hideDelay
  } = props;
  const {
    REACT_APP_PROMISE_TRACKER_DELAY,
    REACT_APP_HIDE_LOADING_SPINNER_DELAY
  } = process.env;
  const DEFAULT_PROMISE_TRACKER_DELAY = 300;
  const DEFAULT_HIDE_LOADING_SPINNER_DELAY = 900;
  const delayAsNumber = Number(delay || REACT_APP_PROMISE_TRACKER_DELAY || DEFAULT_PROMISE_TRACKER_DELAY);
  const hideDelayAsNumber = Number(hideDelay || REACT_APP_HIDE_LOADING_SPINNER_DELAY || DEFAULT_HIDE_LOADING_SPINNER_DELAY);
  const hasPastShowDelay = usePastDelay(delayAsNumber);
  const hasPastHideDelay = usePastDelay(hideDelayAsNumber);
  return (
    <>
      {
        !hasPastShowDelay && hasPastHideDelay
          ? <>
            {console.log(`LoadingSpinner.hasPastShowDelay: ${hasPastShowDelay}`)}
            {console.log(`LoadingSpinner.hasPastHideDelay: ${hasPastHideDelay}`)}
          </>
          : <>
            {console.log(`LoadingSpinner.caller (delay: ${delayAsNumber}, hideDelay: ${hideDelayAsNumber}): ${caller}`)}
            <div className={outerClassName}>
              <LoadingIcon
                outerClassName={iconOuterClassName}
                iconClassName={iconClassName}
                iconTextClassName={iconTextClassName}
                color={color}
                text={text}
              />
            </div>
          </>
      }
    </>
  )
};

LoadingSpinner.propTypes = {
  caller: PropTypes.string.isRequired,
  outerClassName: PropTypes.string,
  iconOuterClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  iconTextClassName: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  delay: PropTypes.number,
  hideDelay: PropTypes.number
};
LoadingSpinner.defaultProps = {
  outerClassName: 'pumahara-loading',
  iconOuterClassName: 'h3 vw-100 top-35pc',
  iconClassName: 'fas pumahara-logo fa-spin',
  iconTextClassName: 'icon-text',
  color: 'default',
  delay: 0,
  hideDelay: 0
};

export default LoadingSpinner;
