import {
  DATE_MOMENT_FORMAT
} from '../date';
import {
  toMoment
} from '../../functions/date';
import {
  handleSort,
  reduceArrayOfArrays
} from '../../functions';

const TODAY_FILTER_DATE_NAME = 'today';
const LAST_WEEK_FILTER_DATE_NAME = 'lastWeek';
const PREV_WEEK_FILTER_DATE_NAME = 'previousWeek';
const THIS_WEEK_FILTER_DATE_NAME = 'thisWeek';
const NEXT_WEEK_FILTER_DATE_NAME = 'nextWeek';
const LAST_MONTH_FILTER_DATE_NAME = 'lastMonth';
const PREV_MONTH_FILTER_DATE_NAME = 'previousMonth';
const THIS_MONTH_FILTER_DATE_NAME = 'thisMonth';
const NEXT_MONTH_FILTER_DATE_NAME = 'nextMonth';
const LAST_YEAR_FILTER_DATE_NAME = 'lastYear';
const PREV_YEAR_FILTER_DATE_NAME = 'previousYear';
const THIS_YEAR_FILTER_DATE_NAME = 'thisYear';
const NEXT_YEAR_FILTER_DATE_NAME = 'nextYear';
const MOMENT_TIME_KEY_DAYS = 'd';
const MOMENT_TIME_KEY_WEEKS = 'w';
const MOMENT_TIME_KEY_MONTHS = 'M';
const MOMENT_TIME_KEY_YEARS = 'y';
const MOMENT_UNIT_OF_TIME_DAY = 'day';
const MOMENT_UNIT_OF_TIME_WEEK = 'week';
const MOMENT_UNIT_OF_TIME_MONTH = 'month';
const MOMENT_UNIT_OF_TIME_YEAR = 'year';
const FILTER_DATE_OPTIONS = [
  {
    offsets: {
      '1': [
        TODAY_FILTER_DATE_NAME
      ]
    },
    timeKey: MOMENT_TIME_KEY_DAYS,
    unitOfTime: MOMENT_UNIT_OF_TIME_DAY
  },
  {
    offsets: {
      '0': [
        LAST_WEEK_FILTER_DATE_NAME,
        PREV_WEEK_FILTER_DATE_NAME
      ],
      '1': [
        THIS_WEEK_FILTER_DATE_NAME
      ],
      '2': [
        NEXT_WEEK_FILTER_DATE_NAME
      ]
    },
    timeKey: MOMENT_TIME_KEY_WEEKS,
    unitOfTime: MOMENT_UNIT_OF_TIME_WEEK
  },
  {
    offsets: {
      '0': [
        LAST_MONTH_FILTER_DATE_NAME,
        PREV_MONTH_FILTER_DATE_NAME
      ],
      '1': [
        THIS_MONTH_FILTER_DATE_NAME
      ],
      '2': [
        NEXT_MONTH_FILTER_DATE_NAME
      ]
    },
    timeKey: MOMENT_TIME_KEY_MONTHS,
    unitOfTime: MOMENT_UNIT_OF_TIME_MONTH
  },
  {
    offsets: {
      '0': [
        LAST_YEAR_FILTER_DATE_NAME,
        PREV_YEAR_FILTER_DATE_NAME
      ],
      '1': [
        THIS_YEAR_FILTER_DATE_NAME
      ],
      '2': [
        NEXT_YEAR_FILTER_DATE_NAME
      ]
    },
    timeKey: MOMENT_TIME_KEY_YEARS,
    unitOfTime: MOMENT_UNIT_OF_TIME_YEAR
  }
];
const TODAY_AS_MOMENT = toMoment(new Date());
const _getStartEndDates = (
  currentDateAsMoment = TODAY_AS_MOMENT,
  filterDateTimeKey = MOMENT_TIME_KEY_DAYS,
  filterDateOffset = 0,
  unitOfTime = MOMENT_UNIT_OF_TIME_DAY,
  stopRecursion = false
) => {
  const offsettedFilterDate = currentDateAsMoment.add(filterDateOffset, filterDateTimeKey);
  const startDate = filterDateOffset === 0 || stopRecursion
    ? offsettedFilterDate.startOf(unitOfTime).format(DATE_MOMENT_FORMAT)
    : currentDateAsMoment => _getStartEndDates(currentDateAsMoment, filterDateTimeKey, filterDateOffset, unitOfTime, true);
  const endDate = filterDateOffset === 0 || stopRecursion
    ? offsettedFilterDate.endOf(unitOfTime).format(DATE_MOMENT_FORMAT)
    : currentDateAsMoment => _getStartEndDates(currentDateAsMoment, filterDateTimeKey, filterDateOffset, unitOfTime, true);
  return {
    startDate,
    endDate
  };
};
const _getFilterDate = (
  filterDateName,
  currentDateAsMoment = TODAY_AS_MOMENT,
  filterDateTimeKey = undefined,
  filterDateOffset = NaN,
  unitOfTime = undefined,
  sortOrder = NaN
) => {
  const stopRecursion = filterDateName.startsWith('last');
  const {
    startDate,
    endDate
  } = _getStartEndDates(
    currentDateAsMoment,
    filterDateTimeKey,
    filterDateOffset,
    unitOfTime,
    stopRecursion
  );
  return {
    name: filterDateName,
    i18Key: `app.common.filterDates.${filterDateName}`,
    startDate,
    endDate,
    sortOrder
  };
};
const _getFilterDates = filterDateOptions => { // debugger;
  const currentDateAsMoment = TODAY_AS_MOMENT;
  let sortOrder = 0;
  const filterDates = filterDateOptions.map(filterDateOption => {
    const {
      offsets,
      timeKey: filterDateTimeKey,
      unitOfTime
    } = filterDateOption;
    const offsetKeys = Object.keys(offsets);
    const filterDates = offsetKeys.map(offsetKey => {
      const filterDateOffset = Number(offsetKey) - 1;
      const filterDateNames = offsets[offsetKey];
      const filterDates = filterDateNames.map(filterDateName => {
        const filterDate = _getFilterDate(
          filterDateName,
          currentDateAsMoment.clone(),
          filterDateTimeKey,
          filterDateOffset,
          unitOfTime,
          sortOrder++
        );
        return filterDate;
      });
      const reducedFilterDates = reduceArrayOfArrays(filterDates);
      return reducedFilterDates;
    });
    const reducedFilterDates = reduceArrayOfArrays(filterDates);
    return reducedFilterDates;
  });
  const reducedFilterDates = reduceArrayOfArrays(filterDates);
  const remappedFilterDates = reducedFilterDates
    .map(reducedFilterDate => [
      [reducedFilterDate.name],
      reducedFilterDate
    ]);
  const filterDatesAsJson = Object.fromEntries(remappedFilterDates);
  return filterDatesAsJson;
};
const FILTER_DATES = {
  ..._getFilterDates(FILTER_DATE_OPTIONS)
};
const _getSpecificFilterDates = onFilter => {
  return Object
    .fromEntries(Object
      .keys(FILTER_DATES)
      .filter(filterDateKey =>
        onFilter(FILTER_DATES[filterDateKey])
      )
      .sort((a, b) =>
        handleSort(a, b, 'desc', 'sortOrder')
      )
      .map(filterDateKey =>
        [
          [FILTER_DATES[filterDateKey].name],
          FILTER_DATES[filterDateKey]
        ]
      )
    );
};
const STANDARD_FILTER_DATES = {
  ..._getSpecificFilterDates(filterDate => typeof filterDate.startDate !== 'function')
};
const REPORT_FILTER_DATES = {
  ..._getSpecificFilterDates(filterDate => [
    PREV_MONTH_FILTER_DATE_NAME,
    THIS_MONTH_FILTER_DATE_NAME,
    NEXT_MONTH_FILTER_DATE_NAME
  ].includes(filterDate.name))
};
// console.log('FILTER_DATES: ', FILTER_DATES);
export {
  TODAY_FILTER_DATE_NAME,
  LAST_WEEK_FILTER_DATE_NAME,
  PREV_WEEK_FILTER_DATE_NAME,
  THIS_WEEK_FILTER_DATE_NAME,
  NEXT_WEEK_FILTER_DATE_NAME,
  LAST_MONTH_FILTER_DATE_NAME,
  PREV_MONTH_FILTER_DATE_NAME,
  THIS_MONTH_FILTER_DATE_NAME,
  NEXT_MONTH_FILTER_DATE_NAME,
  LAST_YEAR_FILTER_DATE_NAME,
  PREV_YEAR_FILTER_DATE_NAME,
  THIS_YEAR_FILTER_DATE_NAME,
  NEXT_YEAR_FILTER_DATE_NAME,
  FILTER_DATES,
  STANDARD_FILTER_DATES,
  REPORT_FILTER_DATES
}
