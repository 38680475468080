import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
  useTranslation
} from 'react-i18next';

const LoadingIcon = props => {
  const {
    outerClassName,
    iconClassName,
    iconTextClassName,
    color,
    text
  } = props;
  const {
    t
  } = useTranslation('common');
  return (
    <div className={outerClassName}>
      <i className={`${iconClassName} text-${color}`}/><b className={`${iconTextClassName} text-${color}`}>{text || t('app.common.loading')}</b>
    </div>
  );
}

LoadingIcon.propTypes = {
  outerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  iconTextClassName: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string
};
LoadingIcon.defaultProps = {
  outerClassName: 'mx-auto my-5 p-5 h3',
  iconClassName: 'fas pumahara-logo fa-spin',
  iconTextClassName: 'icon-text',
  color: 'dark'
};

export default LoadingIcon;
