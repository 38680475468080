import React, {
  Suspense,
  lazy
} from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Switch
} from 'react-router-dom';
import Routes from './components/Routes';
import {
  DEFAULT_LANGUAGE
} from './components/Domain';
import {
  initReactI18next
} from 'react-i18next';
import i18next from 'i18next/dist/esm/i18next';
import Backend from 'i18next-chained-backend/dist/esm/i18nextChainedBackend';
import LocalStorageBackend from 'i18next-localstorage-backend/dist/esm/i18nextLocalStorageBackend';
import HttpApi from 'i18next-http-backend/esm/index';
import Firebase from './components/Domain/Firebase';
import TrackedLoadingSpinner from './components/TrackedLoadingSpinner';

const AppProviders = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-app-providers' */'./components/Contexts/AppProviders/Provider'));
const AppRoute = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-app-route' */'./components/Layouts/AppRoute'));
const LazySizes = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-lazy-sizes' */'./components/LazySizes'));
const activeRouteKeys = Object.keys(Routes).filter(key => Routes[key].isActive && !Routes[key].isHash);
const initPumaharaI18next = {
  interpolation: {
    escapeValue: false
  },
  lng: DEFAULT_LANGUAGE,
  resources: {
    en: {
      common: {
        app: {
          common: {
            loading: 'Loading...'
          }
        }
      } // enCommonJson
    },
    mi: {
      common: {
        app: {
          common: {
            loading: 'Whakauta...'
          }
        }
      } // miCommonJson
    }
  },
  backend: {
    backends: [
      LocalStorageBackend,
      HttpApi
    ],
    backendOptions: [{
      store: localStorage
    }, {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }]
  }
};
const {
  REACT_APP_DEBUG_MODE
} = process.env;

if (REACT_APP_DEBUG_MODE === 'false') {
  window['console']['log'] = () => { };
}
i18next
  .use(Backend)
  .use(initReactI18next)
  .init(initPumaharaI18next);
const App = () => <>
  <Suspense fallback={<></>}>
    <BrowserRouter>
      <LazySizes />
      <Firebase>
        {
          firebase => <>
            <AppProviders
              i18next={i18next}
              backend={firebase}
            >
              <Switch>
                {
                  activeRouteKeys.map(activeRouteKey => {
                    const activeRoute = Routes[activeRouteKey];
                    const {
                      layout,
                      path,
                      component,
                      isExact
                    } = activeRoute;
                    return (
                      <AppRoute
                        layout={layout}
                        path={path}
                        component={component}
                        exact={isExact}
                        key={activeRouteKey}
                      />
                    );
                  })
                }
              </Switch>
            </AppProviders>
          </>
        }
      </Firebase>
    </BrowserRouter>
  </Suspense>
</>;
ReactDOM.render(<>
  <TrackedLoadingSpinner
    caller="tracked:Root"
    color="default"
    text="Loading..."
  />
  <App />
</>, document.getElementById('root'));
