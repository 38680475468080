import {
  energyLevelsAsArray
} from '../../constants/energyLevels';
import {
  fromCamelcaseToTitlecase
} from '../..';

const toEnergyLevelDisplayName = energyLevelIndex => {
  const energyLevel = energyLevelsAsArray[energyLevelIndex] || 'undefined';
  return fromCamelcaseToTitlecase(energyLevel.replace('EnergyLevel', ''));
};

export {
  toEnergyLevelDisplayName
}
