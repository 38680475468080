import {
  pumanawaLevelsAsArray
} from '../../constants/pumanawaLevels';
import {
  fromCamelcaseToTitlecase
} from '../..';

const toPumanawaLevelDisplayName = pumanawaLevelIndex => {
  const pumanawaLevel = pumanawaLevelsAsArray[pumanawaLevelIndex] || 'undefined';
  return fromCamelcaseToTitlecase(pumanawaLevel.replace('PumanawaLevel', ''));
};

export {
  toPumanawaLevelDisplayName
}
