import {
  FirebaseStorageFileTypes,
  FIREBASE_STORAGE_BASE_URL,
  FIREBASE_STORAGE_BUCKET_URL
} from '../../constants/FirebaseStorageFileTypes';
import {
  isNullOrEmpty
} from '..';

const FIREBASE_STORAGE_PREFIX_URL = new URL(FIREBASE_STORAGE_BUCKET_URL, FIREBASE_STORAGE_BASE_URL).toString();
const _generateFirebaseStorageFileLocation = (storageFileUrl, storageFileType = FirebaseStorageFileTypes.imagesFirebaseStorageFileType) => {
  // https://firebasestorage.googleapis.com/v0/b/pumahara-test.appspot.com/o/images%2Fusers%2FXnHfhTJtxXlWRA4I7OGb6Ttxa1dZ%2Fjohn-shortland-450x450.png?alt=media&token=f886a48d-d5dc-4c32-a25c-f808b20dc418
  let firebaseStorageFileLocation = storageFileUrl
    ? storageFileUrl.startsWith(`/${storageFileType}/`)
      ? storageFileUrl
      : storageFileUrl.startsWith(FIREBASE_STORAGE_PREFIX_URL)
        ? storageFileUrl.replace(FIREBASE_STORAGE_PREFIX_URL, '')
        : null
    : null;
  return decodeURIComponent(firebaseStorageFileLocation
    ? firebaseStorageFileLocation.includes('?')
      ? firebaseStorageFileLocation.split('?')[0]
      : firebaseStorageFileLocation
    : '');
};
const generateFirebaseStorageFileLocation = (storageFileUrl, storageFileType = undefined) => {
  const firebaseStorageFileLocation = !isNullOrEmpty(storageFileType)
    ? _generateFirebaseStorageFileLocation(storageFileUrl, storageFileType)
    : (
      _generateFirebaseStorageFileLocation(storageFileUrl, FirebaseStorageFileTypes.imagesFirebaseStorageFileType) ||
      _generateFirebaseStorageFileLocation(storageFileUrl, FirebaseStorageFileTypes.videosFirebaseStorageFileType) ||
      _generateFirebaseStorageFileLocation(storageFileUrl, FirebaseStorageFileTypes.audiosFirebaseStorageFileType) ||
      _generateFirebaseStorageFileLocation(storageFileUrl, FirebaseStorageFileTypes.documentsFirebaseStorageFileType)
    );
  return firebaseStorageFileLocation;
};
const isFirebaseStorageFileLocation = (storageFileUrl, storageFileType = undefined) => !isNullOrEmpty(generateFirebaseStorageFileLocation(storageFileUrl, storageFileType));
const getFirebaseAudioFileLocation = audioUrl => generateFirebaseStorageFileLocation(audioUrl, FirebaseStorageFileTypes.audiosFirebaseStorageFileType);
const isFirebaseAudioFileLocation = audioUrl => isFirebaseStorageFileLocation(audioUrl, FirebaseStorageFileTypes.audiosFirebaseStorageFileType);
const getFirebaseVideoFileLocation = videoUrl => generateFirebaseStorageFileLocation(videoUrl, FirebaseStorageFileTypes.videosFirebaseStorageFileType);
const isFirebaseVideoFileLocation = videoUrl => isFirebaseStorageFileLocation(videoUrl, FirebaseStorageFileTypes.videosFirebaseStorageFileType);
const getFirebaseImageFileLocation = imageUrl => generateFirebaseStorageFileLocation(imageUrl, FirebaseStorageFileTypes.imagesFirebaseStorageFileType);
const isFirebaseImageFileLocation = imageUrl => isFirebaseStorageFileLocation(imageUrl, FirebaseStorageFileTypes.imagesFirebaseStorageFileType);
const generateFirebaseStorageFileUrl = (objectPluralName, primaryKeyName, primaryKey, fileName, firebaseStorageFileType, fileNamePrefix = 'embedded_') => {
  const {
    fileUrlFormat,
    keyFormat,
    filenameFormat
  } = generateFirebaseStorageFileLocationFormat(objectPluralName, primaryKeyName, firebaseStorageFileType);
  const firebaseStorageFileLocation = fileUrlFormat
    .replace(keyFormat, primaryKey)
    .replace(filenameFormat, `${fileNamePrefix}${fileName}`);
  return firebaseStorageFileLocation;
};
const getFirebaseStorageFileType = type => {
  let firebaseStorageFileType = FirebaseStorageFileTypes.documentsFirebaseStorageFileType;
  Object.keys(FirebaseStorageFileTypes).map(key => {
    const currentFirebaseStorageFileType = FirebaseStorageFileTypes[key];
    if (type.startsWith(`${currentFirebaseStorageFileType.replace('s', '')}/`)) {
      firebaseStorageFileType = currentFirebaseStorageFileType;
    }
    return null;
  });
  return firebaseStorageFileType;
};
const generateFirebaseStorageFileLocationFormat = (objectPluralName, primaryKeyName, firebaseStorageFileType = FirebaseStorageFileTypes.imagesFirebaseStorageFileType) => {
  const path = `/${firebaseStorageFileType}/${objectPluralName}`;
  const keyFormat = `{${primaryKeyName}}`;
  const folderUrlFormat = `${path}/${keyFormat}/`;
  const filenameFormat = '{filename}';
  const fileUrlFormat = `${folderUrlFormat}${filenameFormat}`;
  return {
    path,
    keyFormat,
    folderUrlFormat,
    filenameFormat,
    fileUrlFormat
  };
};
const getFirebaseStorageUrl = firebaseStorageFileLocation => new URL(`${FIREBASE_STORAGE_BUCKET_URL}/${encodeURIComponent(firebaseStorageFileLocation.startsWith('/') ? firebaseStorageFileLocation.substring(1) : firebaseStorageFileLocation)}?alt=media`, FIREBASE_STORAGE_BASE_URL).toString();

export {
  generateFirebaseStorageFileLocation,
  isFirebaseStorageFileLocation,
  getFirebaseAudioFileLocation,
  isFirebaseAudioFileLocation,
  getFirebaseVideoFileLocation,
  isFirebaseVideoFileLocation,
  getFirebaseImageFileLocation,
  isFirebaseImageFileLocation,
  generateFirebaseStorageFileUrl,
  getFirebaseStorageFileType,
  generateFirebaseStorageFileLocationFormat,
  getFirebaseStorageUrl
}
