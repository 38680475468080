import {
  isJson,
  toJson,
  isNullOrEmpty
} from '../../../App/functions';

const isContentEmptyAsync = async content => {
  let isEmpty = true;
  try {
    const EditorState = await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-draft-js-editor-state' */'draft-js/lib/EditorState');
    const {
      default: convertFromRaw
    } = await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-draft-js-convert-from-raw-to-draft-state' */'draft-js/lib/convertFromRawToDraftState');
    if (isJson(content)) {
      const editorState = EditorState.createWithContent(convertFromRaw(toJson(content))); // debugger;
      const currentContent = editorState.getCurrentContent();
      isEmpty = !currentContent.hasText();
    }
  } catch (error) {
    console.log('isContentEmptyAsync.error: ', error);
  }
  return isEmpty;
};
const isContentEmpty = content => {
  let isEmpty = true;
  if (isJson(content)) {
    const contentAsJson = toJson(content);
    const contentText = Array.isArray(contentAsJson.blocks) && contentAsJson.blocks.length > 0 && contentAsJson.blocks[0] && contentAsJson.blocks[0].text
      ? contentAsJson.blocks[0].text
      : '';
    isEmpty = isNullOrEmpty(contentText.trim());
  }
  return isEmpty;
};

export {
  isContentEmptyAsync,
  isContentEmpty
}
