const FirebaseStorageFileTypes = {
  imagesFirebaseStorageFileType: 'images',
  videosFirebaseStorageFileType: 'videos',
  audiosFirebaseStorageFileType: 'audios',
  documentsFirebaseStorageFileType: 'documents'
};
const FIREBASE_STORAGE_BASE_URL = 'https://firebasestorage.googleapis.com/';
const FIREBASE_STORAGE_BUCKET_URL = `/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o`;

export {
  FirebaseStorageFileTypes,
  FIREBASE_STORAGE_BASE_URL,
  FIREBASE_STORAGE_BUCKET_URL
}
