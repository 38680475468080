import {
  FILTER_DATES
} from '../../constants/filterDates';

const getFilterDates = (filterDateName, currentDateAsMoment = undefined) => {
  const filterDate = FILTER_DATES[filterDateName];
  const {
    startDate: startDateAsFunction,
    endDate: endDateAsFunction
  } = filterDate;
  const startDate = typeof startDateAsFunction === 'function'
    ? startDateAsFunction(currentDateAsMoment)
    : startDateAsFunction;
  const endDate = typeof endDateAsFunction === 'function'
    ? endDateAsFunction(currentDateAsMoment)
    : endDateAsFunction;
  console.log(`Filter Date: ${JSON.stringify({
    startDate,
    endDate
  }, null, 2)}`);
  return {
    startDate,
    endDate
  };
};

export {
  getFilterDates
}
