import withReactContent from 'sweetalert2-react-content';
import {
  INITIAL_CUSTOMSWAL_OPTIONS
} from '../../constants/customSwal';

const customSwalMixin = async props => {
  const {
    default: Swal
  } = await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-sweetalert2' */'sweetalert2/dist/sweetalert2.all');
  const reactSwal = withReactContent(Swal);
  return reactSwal.mixin(props);
};
const customSwal = async props => {
  const reactSwal = await customSwalMixin(INITIAL_CUSTOMSWAL_OPTIONS);
  return await reactSwal.fire({
    ...INITIAL_CUSTOMSWAL_OPTIONS,
    ...props
  });
};

export {
  customSwalMixin,
  customSwal
}
