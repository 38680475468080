import * as EnergyLevels from '../../../Domain/EnergyLevels';

const energyLevelsAsArray = Object
  .keys(EnergyLevels)
  .map(energyLevelKey =>
    EnergyLevels[energyLevelKey]
  );

export {
  energyLevelsAsArray
}
