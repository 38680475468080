import {
  DEFAULT_MOMENT_TIME_ZONE,
  DATE_MOMENT_FORMAT,
  DATE_TIME_MOMENT_FORMAT
} from '../../constants/date';
import moment from 'moment-timezone';
import 'moment/locale/mi';

const toMoment = (value, dateFormat = DATE_TIME_MOMENT_FORMAT, isStrict = false) => moment(value || new Date(), dateFormat, isStrict).tz(DEFAULT_MOMENT_TIME_ZONE);
const isDate = (value, dateFormat = DATE_TIME_MOMENT_FORMAT) => value && toMoment(value, dateFormat, true).isValid();
const isBetweenDates = (value, startDate, endDate) => value && toMoment(value).startOf('day').isBetween(startDate, endDate, undefined, '[]');
const toDate = (value, dateFormat = DATE_TIME_MOMENT_FORMAT) => toMoment(value, dateFormat).toDate();
const toEndOfDayMoment = (value, dateFormat = DATE_TIME_MOMENT_FORMAT) => toMoment(value, dateFormat)
  .startOf('day')
  .add(1, 'day')
  .subtract(1, 'milliseconds');
const toFormattedDate = (value, dateFormat = DATE_TIME_MOMENT_FORMAT, dateFormatOverride = DATE_MOMENT_FORMAT) => isDate(value, dateFormat)
  ? toMoment(value, dateFormat).format(dateFormatOverride)
  : value;
const toFormattedDateTime = (value, dateFormat = DATE_TIME_MOMENT_FORMAT, dateFormatOverride = DATE_TIME_MOMENT_FORMAT) => isDate(value, dateFormat)
  ? toMoment(value, dateFormat).format(dateFormatOverride)
  : value;
const toTimeAgo = (value, dateFormat = DATE_TIME_MOMENT_FORMAT) => isDate(value, dateFormat)
  ? toMoment(value, dateFormat).fromNow()
  : value;
const toFormattedEndOfDayDateTime = (value, dateFormat = DATE_TIME_MOMENT_FORMAT, dateFormatOverride = DATE_TIME_MOMENT_FORMAT) => isDate(value, dateFormat)
  ? toEndOfDayMoment(value, dateFormat).format(dateFormatOverride)
  : value;

export {
  toMoment,
  isDate,
  isBetweenDates,
  toDate,
  toEndOfDayMoment,
  toFormattedDate,
  toFormattedDateTime,
  toTimeAgo,
  toFormattedEndOfDayDateTime
}
