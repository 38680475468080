import React, {
  useEffect,
  Suspense,
  useState,
  lazy
} from 'react';
import {
  inputTypes
} from '../../FormGroupRow';
import {
  Validator
} from 'jsonschema';
import {
  DEFAULT_LANGUAGE,
  DEFAULT_THEME
} from '../../Domain';
import profileImage from '../../../assets/img/profile.png';
import {
  INITIAL_CUSTOMSWAL_OPTIONS
} from '../../App/constants/customSwal';
import {
  toCamelcase,
  isUrlValid,
  SuspenseFallback,
  customSwalMixin,
  isNullOrEmpty,
  sleep,
  toJson,
  callFunction
} from '../../App/functions';

const AdminImportTable =lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-admin-import-table' */'../../AdminImportTable'));
const FormGroupRow = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-form-group-row' */'../../FormGroupRow'));
const FirebaseImage = lazy(async () => await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-image' */'../../FirebaseImage'));
const importFile = async importFileOptions => {
  const handleUploadFile = async e => {
    e.preventDefault();
    defaultSteps[0].value = e.target.files[0];
    disableConfirmButton(false);
  };
  const validateData = async () => {
    const dataFile = defaultSteps[0].value;
    if (dataFile) {
      const fileReader = new FileReader();
      let isFileReaderLoading = true;
      let fileReaderLoadingCounter = 0;
      fileReader.onloadend = e => {
        const {
          result: dataAsText
        } = e.target;
        const dataAsJson = toJson(dataAsText);
        const dataSchema = {
          type: 'array',
          items: {
            properties: {
              photoURL: {
                type: 'string'
              },
              emailAddress: {
                type: 'string'
              },
              displayName: {
                type: 'string'
              },
              role: {
                type: 'string'
              }
            },
            required: ['emailAddress', 'displayName', 'role']
          }
        };
        const v = new Validator();
        const validationResult = v.validate(dataAsJson, dataSchema);
        const validationErrors = validationResult
          ? validationResult.errors
          : [];
        const dataHasErrors = validationResult &&
          validationErrors &&
          Array.isArray(validationErrors) &&
          validationErrors.length > 0;
        console.log(`validateData.validationResult: ${JSON.stringify(validationResult, null, 2)}`); // debugger;
        defaultSteps[1].value = {
          validationErrors,
          dataAsJson
        };
        disableConfirmButton(dataHasErrors);
        isFileReaderLoading = false;
      };
      fileReader.readAsText(dataFile);
      while (isFileReaderLoading) {
        await sleep(1000);
        if (++fileReaderLoadingCounter > 3000) {
          break;
        }
      }
      return !isFileReaderLoading;
    } else {
      return false;
    }
  };
  const handleAdminImportTableLoad = () => {
    const keyField = 'emailAddress';
    const columns = [
      {
        dataField: 'status',
        text: 'Status',
        sort: false,
        style: {
          whiteSpace: 'break-spaces'
        }
      },
      {
        dataField: 'photoURL',
        text: 'Photo URL',
        sort: false,
        style: {
          width: '85px',
          whiteSpace: 'break-spaces'
        },
        headerStyle: {
          width: '85px'
        },
        formatter: (cell, row) => <>
          <FirebaseImage
            alt={row.displayName || ''}
            src={profileImage}
            imageUrl={cell}
            imageResize="sm"
            width="65"
            height="65"
          />
          {/* <img
            alt={row.displayName || ''}
            src={cell || profileImage}
            width="65"
            height="65"
          /> */}
        </>
      },
      {
        dataField: keyField,
        text: 'Email Address',
        sort: false,
        style: {
          whiteSpace: 'break-spaces'
        }
      },
      {
        dataField: 'displayName',
        text: 'Display Name',
        sort: false,
        style: {
          whiteSpace: 'break-spaces'
        }
      },
      {
        dataField: 'role',
        text: 'Role',
        sort: false,
        style: {
          whiteSpace: 'break-spaces'
        }
      }
    ];
    const {
      validationErrors,
      dataAsJson
    } = defaultSteps[1].value;
    const hasValidationErrors = validationErrors &&
      Array.isArray(validationErrors) &&
      validationErrors.length > 0;
    const data = [];
    dataAsJson.map((d, index) => {
      const newData = {
        ...d,
        status: ''
      };
      if (hasValidationErrors) {
        const dataValidationErrors = validationErrors.filter(ve => ve.path && ve.path[0] === index);
        if (dataValidationErrors) {
          newData.status = dataValidationErrors.map(dve => dve.message).join(', ');
        }
      }
      data.push(newData);
      return null;
    });
    // debugger;
    return {
      keyField,
      columns,
      data: data
    };
  };
  const saveData = async () => {
    const getRoles = role => {
      const roles = {};
      const roleParts = role.split(',');
      roleParts.map(rolePart => {
        const roleName = toCamelcase(`${rolePart} Role`);
        roles[roleName] = roleName;
        return null;
      });
      return roles;
    };
    const saveAuthUsers = async (data, authUserUid) => {
      const {
        REACT_APP_GOOGLE_BASE_CLOUD_FUNCTIONS_URL: GCF_URL
      } = process.env;
      const authUsers = data.map(d => {
        const {
          photoURL,
          emailAddress,
          displayName,
          role
        } = d;
        const roles = getRoles(role);
        const newData = {
          disabled: false,
          displayName,
          email: emailAddress,
          photoURL: isUrlValid(photoURL)
            ? photoURL
            : undefined,
          isNew: true,
          createdBy: authUserUid,
          language: DEFAULT_LANGUAGE,
          password: emailAddress,
          providerData: [{
            email: emailAddress,
            providerId: 'password',
            uid: ''
          }],
          roles,
          theme: DEFAULT_THEME,
          updatedBy: authUserUid,
          version: 1
        };
        return newData;
      });
      const result = await callFunction(backend, GCF_URL, 'saveAuthUsers', { authUsers });
      return result;
    };
    const {
      dataAsJson: dataFile
    } = defaultSteps[1].value; // debugger;
    const savedAuthUserUids = await saveAuthUsers(dataFile, authUser.uid);
    defaultSteps[2].value = `Imported ${savedAuthUserUids.data.length} out of ${dataFile.length} users`;
  };
  const ImportText = props => {
    const [state, setState] = useState({
      isLoading: true,
      text: ''
    });
    const {
      isLoading,
      text
    } = state;
    useEffect(() => {
      if (isLoading) {
        const {
          onLoad
        } = props;
        setState(s => ({
          ...s,
          isLoading: false,
          text: onLoad()
        }));
      }
      return () => { }
    }, [props, isLoading]);
    return (
      <>
        <span>{text}</span>
      </>
    );
  };
  const {
    steps,
    t,
    backend,
    authUser,
    ...otherImportFileOptions
  } = importFileOptions;
  const UPLOAD_FILE_TITLE = 'Upload File';
  const VALIDATE_DATA_TITLE = 'Validate Data';
  const SAVE_DATA_TITLE = 'Save Data';
  const defaultSteps = [
    {
      title: UPLOAD_FILE_TITLE,
      html: <>
        <Suspense fallback={<SuspenseFallback />}>
          <FormGroupRow
            primaryInputProps={{
              primaryInputType: inputTypes.roundedPillInputWithIcon,
              primaryInputSm: 12,
              id: 'uploadFileInput',
              placeholder: t('app.common.uploadFile'),
              includeFileInput: true,
              onFileInputChange: handleUploadFile,
              readOnly: true
            }}
          />
        </Suspense>
      </>,
      value: null
    }, {
      title: VALIDATE_DATA_TITLE,
      html: <>
        <Suspense fallback={<SuspenseFallback />}>
          <FormGroupRow
            primaryInputProps={{
              primaryInputType: inputTypes.children,
              primaryInputSm: 12,
              primaryColClassName: 'admin-import-table-container table-responsive',
              primaryChildren: (
                <>
                  <AdminImportTable
                    onLoad={handleAdminImportTableLoad}
                  />
                </>
              )
            }}
          />
        </Suspense>
      </>,
      value: null
    }, {
      title: SAVE_DATA_TITLE,
      html: <>
        <Suspense fallback={<SuspenseFallback />}>
          <FormGroupRow
            primaryInputProps={{
              primaryInputType: inputTypes.children,
              primaryInputSm: 12,
              primaryChildren: (
                <>
                  <ImportText
                    onLoad={() => defaultSteps[2].value}
                  />
                </>
              )
            }}
          />
        </Suspense>
      </>,
      value: null
    }
  ];
  const stepsToUse = steps || defaultSteps;
  const customSwalOptions = {
    ...INITIAL_CUSTOMSWAL_OPTIONS,
    progressSteps: stepsToUse.map((stepToUse, stepToUseIndex) => (stepToUseIndex + 1).toString()),
    reverseButtons: true,
    width: '100%'
  };
  const importFileSwal = await customSwalMixin(customSwalOptions);
  const disableConfirmButton = async disabled => {
    const confirmButton = importFileSwal.getConfirmButton();
    confirmButton.disabled = disabled;
  };
  for (let currentStep = 0; currentStep < stepsToUse.length;) { // debugger;
    if (currentStep === -1) {
      break;
    }
    const stepToUse = stepsToUse[currentStep];
    const {
      value,
      isConfirmed,
      isDismissed
    } = await importFileSwal.fire({
      title: stepToUse.title,
      html: stepToUse.html,
      confirmButtonText: currentStep < (stepsToUse.length - 1)
        ? t('app.common.next')
        : t('app.common.close'),
      cancelButtonText: currentStep === 0
        ? t('app.common.cancel')
        : t('app.common.previous'),
      showCancelButton: true,
      currentProgressStep: currentStep,
      didRender: async () => {
        await disableConfirmButton(isNullOrEmpty(stepToUse.value) && Number(stepToUse.value) === (stepsToUse.length - 1));
      },
      preConfirm: async () => {
        console.log(`stepToUse.title: ${stepToUse.title}`);
        switch (stepToUse.title) {
          case UPLOAD_FILE_TITLE:
          default:
            const isValidData = await validateData();
            if (!isValidData) {
              return false;
            }
            break;
          case VALIDATE_DATA_TITLE:
            const isSavedData = await saveData();
            if (!isSavedData) {
              return false;
            }
            break;
          case SAVE_DATA_TITLE:
            // eslint-disable-next-line
            window.location.href = window.location.href;
            break;
        }
      },
      ...otherImportFileOptions
    });
    stepToUse.value = value;
    if (isConfirmed) {
      currentStep++;
    } else if (isDismissed) {
      currentStep--;
    } else {
      break;
    }
  }
};

export default importFile;
