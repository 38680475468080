import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import LoadingSpinner from '../LoadingSpinner';
import {
  getUsePromiseTrackerConfig
} from '../App';
import {
  usePromiseTracker
} from 'react-promise-tracker';

const TrackedLoadingSpinner = props => {
  const {
    area,
    delay
  } = props;
  const usePromiseTrackerConfig = getUsePromiseTrackerConfig(area, delay);
  const {
    promiseInProgress
  } = usePromiseTracker(usePromiseTrackerConfig);
  // console.log(`usePromiseTrackerConfig: ${JSON.stringify(usePromiseTrackerConfig, null, 2)}`);
  return (
    <>
    {
      promiseInProgress
        ? <LoadingSpinner {...props} />
        : null
    }
    </>
  );
};

TrackedLoadingSpinner.propTypes = {
  caller: PropTypes.string.isRequired,
  outerClassName: PropTypes.string,
  iconOuterClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  delay: PropTypes.number,
  hideDelay: PropTypes.number
};
TrackedLoadingSpinner.defaultProps = {
  outerClassName: 'pumahara-loading',
  iconOuterClassName: 'h3 vw-100 top-35pc',
  iconClassName: 'fas pumahara-logo fa-spin',
  delay: 0,
  hideDelay: 0
};

export default TrackedLoadingSpinner;
