const DEFAULT_MOMENT_TIME_ZONE = 'Pacific/Auckland';
const DATE_MOMENT_FORMAT = 'DD/MM/YYYY';
const TIME_NO_SECONDS_MOMENT_FORMAT = 'HH:mm';
const TIME_MOMENT_FORMAT = `${TIME_NO_SECONDS_MOMENT_FORMAT}:ss`;
const DATE_TIME_MOMENT_FORMAT = `${DATE_MOMENT_FORMAT} ${TIME_MOMENT_FORMAT}`;
const ISO8601_DATE_FORMAT = 'YYYY-MM-DD';
const ISO8601_TIME_FORMAT = `${TIME_MOMENT_FORMAT}.SSSS`;
const ISO8601_DATE_TIME_FORMAT = `${ISO8601_DATE_FORMAT}T${ISO8601_TIME_FORMAT}`;
const LOCAL_LONG_DATE_TIME_MOMENT_FORMAT = 'LLLL';
const JOURNAL_DATE_FORMAT = `${DATE_MOMENT_FORMAT} ${TIME_NO_SECONDS_MOMENT_FORMAT}`;

export {
  DEFAULT_MOMENT_TIME_ZONE,
  DATE_MOMENT_FORMAT,
  TIME_NO_SECONDS_MOMENT_FORMAT,
  TIME_MOMENT_FORMAT,
  DATE_TIME_MOMENT_FORMAT,
  ISO8601_DATE_FORMAT,
  ISO8601_TIME_FORMAT,
  ISO8601_DATE_TIME_FORMAT,
  LOCAL_LONG_DATE_TIME_MOMENT_FORMAT,
  JOURNAL_DATE_FORMAT
}
