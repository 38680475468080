import {
  DATE_MOMENT_FORMAT
} from '../date';
import {
  toEnergyLevelDisplayName
} from '../../functions/energyLevels';
import {
  toPumanawaLevelDisplayName
} from '../../functions/pumanawaLevels';

const chartOptions = {
  defaults: {
    global: {
      responsive: true,
      layout: {
        padding: 0
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: '1rem'
        }
      },
      elements: {
        line: {
          tension: 0.5,
          borderWidth: 3
        },
        arc: {
          borderWidth: 4
        }
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false
      }
    }
  }
};
const energyLevelsChartOptions = {
  scales: {
    xAxes: [
      {
        // type: 'category',
        type: 'time',
        // gridLines: {
        //   display: false
        // },
        time: {
          unit: 'day',
          tooltipFormat: DATE_MOMENT_FORMAT
        },
        offset: true,
        scaleLabel: {
          display: true,
          labelString: 'Journal Dates'
        }
      }
    ],
    yAxes: [
      {
        // type: 'category',
        ticks: {
          callback: energyLevelIndex => {
            if (energyLevelIndex % 1 === 0) {
              return toEnergyLevelDisplayName(energyLevelIndex);
            }
          }
        },
        offset: true,
        scaleLabel: {
          display: true,
          labelString: 'Energy Levels'
        }
      }
    ],
  },
  tooltips: {
    callbacks: {
      label: (item, data) => { // debugger;
        let content = '';
        const {
          datasetIndex,
          yLabel
        } = item;
        const {
          datasets
        } = data;
        const energyLevelDisplayName = toEnergyLevelDisplayName(Number(yLabel));
        if (datasets.length > 1) {
          const label = datasets[datasetIndex].label || '';
          content = `${content}${label}`;
        }
        content = `${content}${energyLevelDisplayName}`;
        return content;
      }
    }
  }
};
const pumanawaLevelsChartOptions = {
  scales: {
    xAxes: [
      {
        // type: 'category',
        type: 'time',
        // gridLines: {
        //   display: false
        // },
        time: {
          unit: 'day',
          tooltipFormat: DATE_MOMENT_FORMAT
        },
        offset: true,
        scaleLabel: {
          display: true,
          labelString: 'Journal Dates'
        }
      }
    ],
    yAxes: [
      {
        // type: 'category',
        ticks: {
          callback: pumanawaLevelIndex => {
            if (pumanawaLevelIndex % 1 === 0) {
              return toPumanawaLevelDisplayName(pumanawaLevelIndex);
            }
          }
        },
        offset: true,
        scaleLabel: {
          display: true,
          labelString: 'Pumanawa Levels'
        }
      }
    ],
  },
  tooltips: {
    callbacks: {
      label: (item, data) => { // debugger;
        let content = '';
        const {
          datasetIndex,
          yLabel
        } = item;
        const {
          datasets
        } = data;
        const pumanawaLevelDisplayName = toPumanawaLevelDisplayName(Number(yLabel));
        if (datasets.length > 1) {
          const label = datasets[datasetIndex].label || '';
          content = `${content}${label}`;
        }
        content = `${content}${pumanawaLevelDisplayName}`;
        return content;
      }
    }
  }
};

export {
  chartOptions,
  energyLevelsChartOptions,
  pumanawaLevelsChartOptions
}
