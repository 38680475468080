import * as PumanawaLevels from '../../../Domain/PumanawaLevels';

const pumanawaLevelsAsArray = Object
  .keys(PumanawaLevels)
  .map(pumanawaLevelKey =>
    PumanawaLevels[pumanawaLevelKey]
  );

export {
  pumanawaLevelsAsArray
}
