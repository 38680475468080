const INITIAL_CUSTOMSWAL_OPTIONS = {
  buttonsStyling: false,
  backdrop: 'rgba(0, 0, 0, 0.85)',
  customClass: {
    popup: 'bg-pumahara',
    title: 'text-pumahara',
    content: 'z-index-2',
    input: 'form-control',
    confirmButton: 'btn btn-success text-uppercase mx-3',
    cancelButton: 'btn btn-danger text-uppercase mx-3'
  },
  target: 'div[data-theme]'
};

export {
  INITIAL_CUSTOMSWAL_OPTIONS
}
