const DEFAULT_THEME = 'default';
const themes = {
  default: {
    displayName: 'Default'
  },
  black: {
    displayName: 'Black'
  },
  purple: {
    displayName: 'Purple'
  },
  red: {
    displayName: 'Red'
  },
  teal: {
    displayName: 'Teal'
  },
  'blue-gradient': {
    displayName: 'Blue Gradient'
  },
  'yellow-gradient': {
    displayName: 'Yellow Gradient'
  },
  'green-gradient': {
    displayName: 'Green Gradient'
  }
};
const DEFAULT_LANGUAGE = 'en';
const languages = {
  [DEFAULT_LANGUAGE]: {
    code: DEFAULT_LANGUAGE,
    displayName: 'English'
  },
  mi: {
    code: 'mi',
    displayName: 'Māori'
  }
};

export {
  DEFAULT_THEME,
  themes,
  DEFAULT_LANGUAGE,
  languages
};
