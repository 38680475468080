import {
  Suspense
} from 'react';
import PageLoadingSpinner from '../../../PageLoadingSpinner';
import LoadingSpinner from '../../../LoadingSpinner';

const withSuspense = (Component, caller, usePageLoadingSpinner = true) => props => (
  <Suspense fallback={
    usePageLoadingSpinner
      ? <PageLoadingSpinner caller={caller} />
      : <LoadingSpinner caller={caller} />
  }>
    <Component {...props} />
  </Suspense>
);

export {
  withSuspense
};
